// src/pages/404.js
import React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <div className="container h-screen flex flex-col justify-center">
    <div>
      <h1 className="font-display text-gold rfs:text-6xl">Page not found!</h1>
      <div className="w-12 mt-5 separator my-6"></div>
      <p className="mb-6">You must have picked the wrong door!</p>
      <Link to="/" className="btn">
        Back to home
      </Link>
    </div>
  </div>
)

export default NotFoundPage